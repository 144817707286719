<!--
* @description airoad页面
* @fileName airoad.vue
* @author liulian
* @date 2023/07/17 15:40:16
-->
<template>
  <div class="airoad">
    <div class="a_top">
      <img class="a_t_bg" :src="$t(`airoad.banner`)" alt="banner" />
    </div>
    <div class="a_main">
      <div class="a_title text-align-center font-size-36 font-weight-500 font-color-black-1D1D1F">{{ $t("airoad.section1_title") }}</div>
      <div class="a_m_descript font-size-18 font-weight-400">{{ $t("airoad.section1_content") }}</div>
      <div class="a_title text-align-center font-size-36 font-weight-500 font-color-black-1D1D1F">{{ $t("airoad.section2_title") }}</div>
      <div class="a_m_list">
        <div class="a_m_l_item flex-row align-center" v-for="(item, index) in lists" :key="index">
          <!-- 多专业协同特殊 -->
          <template v-if="item.key === 'dzyxt'">
            <div class="a_m_l_i_left step_bg">
              <img class="bg_img" src="@/assets/images/productImg/step_bg@2x.png" alt="步骤背景图">
              <div class="l_i_r_title flex-row align-center font-size-24 font-weight-600" style="width: 420px;">
                  <span>{{ $t(`airoad.${item.name}`) }}</span>
                  <div class="icon_box flex-row align-center"><span></span><span></span><span></span></div>
                </div>
              <div class="a_m_l_con">
                <div class="a_m_l_c_item" v-for="(i, index1) in item.children" :key="index1">
                  <!--  :class="index1 === 0 ? 'l_c_i_title_yellow' : ''" -->
                  <div class="l_c_i_title font-size-18 font-weight-500">{{ $t(`airoad.${i.name}`) }}</div>
                  <!-- <div class="l_c_i_con font-size-18 font-weight-400">{{ $t(`airoad.${i.con}`) }}</div> -->
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="a_m_l_i_left">
              <template v-if="(index + 1) % 2 === 0">
                <div class="l_i_r_title flex-row align-center font-size-24 font-weight-600">
                  <span>{{ $t(`airoad.${item.name}`) }}</span>
                  <div class="icon_box flex-row align-center"><span></span><span></span><span></span></div>
                </div>
                <div class="l_i_con font-size-18 font-weight-400">{{ $t(`airoad.${item.con}`) }}</div>
              </template>
              <template v-else>
                <img class="i_cover" :src="item.cover" alt="功能两点封面图" />
              </template>
            </div>
          </template>
          <div class="a_m_l_i_right">
            <template v-if="(index + 1) % 2 === 0">
              <img class="i_cover" :src="item.cover" alt="功能两点封面图" />
            </template>
            <template v-else>
              <div class="l_i_r_title flex-row align-center font-size-24 font-weight-600">
                <span>{{ $t(`airoad.${item.name}`) }}</span>
                <div class="icon_box flex-row align-center"><span></span><span></span><span></span></div>
              </div>
              <div class="l_i_con font-size-18 font-weight-400">{{ $t(`airoad.${item.con}`) }}</div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "airoad",
  components: {},
  data() {
    return {
      lists: [],
    }
  },
  computed: {},
  created() {
    this.getInit()
  },
  mounted() {},
  methods: {
    getInit() {
      this.lists = [
        {
          cover: require("../../../assets/images/productImg/gnld_1@2x.png"),
          name: "section2_list1_item1",
          con: "section2_list1_content1",
        },
        {
          cover: require("../../../assets/images/productImg/gnld_2@2x.png"),
          name: "section2_list1_item2",
          con: "section2_list1_content2",
        },
        {
          cover: require("../../../assets/images/productImg/gnld_3@2x.png"),
          name: "section2_list1_item3",
          con: "section2_list1_content3",
        },
        {
          cover: require("../../../assets/images/productImg/gnld_4@2x.png"),
          name: "section2_list1_item4",
          key: "dzyxt",
          con: "section2_list1_content4",
          children: [
            {
              name: "section2_list1_content4_title1",
              con: "section2_list1_content4_content1",
            },
            {
              name: "section2_list1_content4_title2",
            },
            {
              name: "section2_list1_content4_title3",
            },
            {
              name: "section2_list1_content4_title4",
            },
          ],
        },
      ]
    },
  },
}
</script>

<style scoped lang="scss">
.airoad {
  background: #ffffff;
  padding-bottom: 56px;
  .a_top {
    margin-bottom: 64px;
    .a_t_bg {
      width: 100%;
      height: 400px;
    }
  }

  .a_main {
    width: 1200px;
    margin: auto;
    .a_m_descript {
      color: rgba(29, 29, 31, 0.8);
      line-height: 36px;
      letter-spacing: 2px;
      text-indent: 36px;
      margin-top: 14px;
      text-align: justify;
      margin-bottom: 46px;
    }
    .a_m_list {
      margin-top: 32px;
      .a_m_l_item {
        margin-bottom: 32px;
        .a_m_l_con{
          margin-top: 33px;
        }
        .a_m_l_i_left {
          margin-right: 54px;
          .l_i_r_title{
            margin-left: 46px;
          } 
          .l_i_con{
            margin-left: 46px;
          }  
          .a_m_l_con{
            margin-left: 46px;
          }

          .a_m_l_c_item {
            margin-bottom: 24px;
            .l_c_i_title {
              color: rgba(29, 29, 31, 0.7);
              height: 32px;
              line-height: 33px;
            }
            .l_c_i_title_yellow {
              color: #ff9413;
            }
            .l_c_i_con {
              color: rgba(29, 29, 31, 0.6);
              line-height: 32px;
              margin-top: 4px;
            }
          }
        }
        .step_bg{
          position: relative;
        }
        .bg_img{
          position: absolute;
          top: 70px;
          left: 0px;
          width: 24px;
          height: 192px;
        }
        .i_cover {
          width: 720px;
          height: 425px;
        }
        .l_i_r_title {
          color: rgba(29, 29, 31, 0.75);
        }
        .l_i_con {
          color: rgba(29, 29, 31, 0.6);
          line-height: 32px;
          margin-top: 22px;
          text-align: justify;
        }
        .icon_box {
          margin-left: 16px;
          span {
            position: relative;
            display: block;
            height: 18px;
            width: 18px;
          }
          span::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            border: 12px solid #c8c8c8;
            border-top: 9px solid transparent;
            border-left: unset;
            border-bottom: 9px solid transparent;
          }
          span:nth-child(2)::before {
            border: 12px solid #8e8e8e;
            border-top: 9px solid transparent;
            border-left: unset;
            border-bottom: 9px solid transparent;
          }
          span:nth-child(3)::before {
            border: 12px solid #504f50;
            border-top: 9px solid transparent;
            border-left: unset;
            border-bottom: 9px solid transparent;
          }
        }
      }
      .a_m_l_item:last-child{
        margin-bottom: unset;
      }
    }
  }
}
</style>
